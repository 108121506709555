/*
 * @Author: Alan
 * @Date: 2022-03-07 11:49:02
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-06 00:09:32
 * @Description: Banner
 * @FilePath: /F1-M1-WEB-Code/components/Banner/img.js
 */
import React from "react";
import Router from "next/router";
import LaunchGame from "@/Games/openGame";
import Image from "next/image";
import { getQueryVariable, isGuest, isThisAllowGuestOpenGame } from '$ACTIONS/helper'
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from '$ACTIONS/TlcRequest';
import {menuBarData} from "$DATA/header.static.js";
import { connect } from 'react-redux';
import { changeTabAction } from "../../store/thunk/promotionThunk";
// import LazyLoad from 'react-lazyload';

class Banner extends React.Component {
    constructor() {
        super();
        this.state = {
            WalletCloseBanner: false,
        };
    }
    componentDidMount() {
        this.setState({
            WalletCloseBanner: sessionStorage.getItem("WalletCloseBanner"),
        });
    }

    CloseBanner() {
        sessionStorage.setItem("WalletCloseBanner", true);
        this.setState({
            WalletCloseBanner: true,
        });
    }

    /**
     * @description: 轮播图跳转动作
     * @param {*} data
     * @return {*}
     */

    async BannerAction(data) {
        let item = data.action;
        let isLogin = localStorage.getItem("access_token");
        const gmaeCategories = this.props.gameInfo?.categories ?? [];
        const gamesToFilter = this.props.gameInfo?.gamesToFilter ?? [];
        if (!item.actionId || item.actionId == 0) {
            return;
        }
        console.log("----------------->", item);
        switch (item.actionId) {
            case 28:
                if(item.url === "/euro2024"){
                    Pushgtagdata("Home","Click Feature  Banner (Euro2024)","Home_C_2024EuroFeatureBanner");
                    Router.push('/cn/euro2024');
                    return;
                }
                if (item.url == '/rewards-centre') {
                    Pushgtagdata(
                        'Home',
                        'Click Feature Banner',
                        'Home_C_FeatureBanner',
                        'Home_C_FeatureBanner_ActivityName',
                        '任务中心'
                    );
                    if (!isLogin) {
                        global.goUserSign("1");
                        return
                    }
                    Router.push('/cn/rewards-centre');
                    return;
                }
                location.href = item.url;
                break;
            case 29:
                // if (!localStorage.getItem("access_token")) {
                //     global.goUserSign("1");
                //     return;
                // }
                const res = await get(ApiPort.CMSPromotionDetail + `id=${item.ID}`);
                if (res.promoMainCategory === "Daily Deal") {
                    Router.push(`/cn/Promotions?id=${item.ID}&jumpfrom=BANNER&type=DailyDeal`);
                    this.props.changeTab && this.props.changeTab("4");
                } else {
                    Router.push(`/cn/Promotions?id=${item.ID}&jumpfrom=BANNER`);
                    this.props.changeTab && this.props.changeTab("1");
                };
                break;
            case 30:
                Router.push("/cn/Sponsor");
                break;
            case 31:
                if (!isLogin) {
                    global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                    return;
                }
                global.showDialog({ key: 'wallet:{"type": "deposit"}' });
                break;
            case 32:
            case 34:
                let gameCatCode
                const codelist = gmaeCategories
                    .flatMap(category =>
                        (category.subProviders || [])
                            .filter(subProvider => subProvider.code && !gamesToFilter.includes(subProvider.code))
                            .map(subProvider => subProvider.code)
                    );

                if (codelist.includes(item.cgmsVendorCode)) {
                    const itemCode = gmaeCategories.find(category =>
                        category.subProviders.some(subProvider => subProvider.code === item.cgmsVendorCode)
                    )?.code;

                    if (itemCode) {
                        gameCatCode = menuBarData.find(data => data.enName === itemCode)?.path;
                    }
                }
                let GameData = {
                    gameCatCode: gameCatCode,
                    provider: item.cgmsVendorCode,
                    gameId: item.gameId,
                    type: "Game",
                };
                if(item.actionId == 34){
                    if (!isLogin) {
                        global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                        return;
                    }
                    else {
                        let gameInfo = {
                            gameName: "Sportsbook",
                            provider: "IPSB",
                            gameId: "0",
                            imageUrl: "",
                            gameCatCode: "Sportsbook",
                            OpenGamesListPage: false
                        }
                        if(item.sportLaunchType === "HOME"){
                            //打开IM体育
                            this.QuickStartGame.openGame(gameInfo);
                            return;

                        }
                        if(item.sportLaunchType === "DEEPLINK"){
                            //打开IM体育，需修改返回的url然后打开游戏
                            if(item.eventId && item.sportId){
                                gameInfo={
                                    ...gameInfo,
                                    event_Id:item.eventId,
                                    sport_Id:item.sportId
                                }
                                this.QuickStartGame.openGame(gameInfo);
                                return;
                            }
                            else{
                                this.QuickStartGame.openGame(gameInfo);
                                return;
                            }
                        }
                    }
                }

                const SHOW_SPORTSBOOK_VENDOR_LOBBY_INSTEAD_OF_FE_LOBBY = ['IPSB', 'SBT', 'OWS'];
                const SHOW_ESPORT_VENDOR_LOBBY_INSTEAD_OF_FE_LOBBY = ['IPES', 'TFG'];
                const SHOW_KENOLOTTERY_VENDOR_LOBBY_INSTEAD_OF_FE_LOBBY = ['SGW', 'LBS'];
                let qsVendor = '', isUseVendorLobbyInsteadOfFELobby;
                if (SHOW_SPORTSBOOK_VENDOR_LOBBY_INSTEAD_OF_FE_LOBBY.includes(item.cgmsVendorCode)) {
                    qsVendor = 'Sportsbook'; isUseVendorLobbyInsteadOfFELobby = true;
                }
                if (SHOW_ESPORT_VENDOR_LOBBY_INSTEAD_OF_FE_LOBBY.includes(item.cgmsVendorCode)) {
                    qsVendor = 'ESports'; isUseVendorLobbyInsteadOfFELobby = true;
                }
                if (SHOW_KENOLOTTERY_VENDOR_LOBBY_INSTEAD_OF_FE_LOBBY.includes(item.cgmsVendorCode)) {
                    qsVendor = 'KenoLottery'; isUseVendorLobbyInsteadOfFELobby = true;
                }
                if (isUseVendorLobbyInsteadOfFELobby) {
                    if (isGuest() && !isThisAllowGuestOpenGame(qsVendor, item.cgmsVendorCode)) {
                        global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                        return;
                    }
                    let go = (
                        /* concat OpenGame path: see also components/Games/openGame.js */
                        `/cn/Games/OpenGame?vendor=${qsVendor}&name=${
                            item.cgmsVendorCode
                        }&id=0&type=&demo=${isGuest() ? 'true' : 'false'}&launchGameCode=&trigger_from_game_lobby_button=NO`
                    );
                    Router.push(go);
                    return;
                }

                if (item.launchMode == "game_id") {
                    if (!isLogin) {
                        global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                        return
                    }
                    this.QuickStartGame.openGame(GameData);
                    return
                }

                /* if (item.cgmsVendorCode == "VTG") {
                    Router.push(
                        "/cn/Games/Sportsbook/lobby/?name=VTG&type=&id=429"
                    );
                    return;
                } */
                const LobbyVSvendor = {
                    LiveCasino: ['WEC', 'EVO', 'AGL', 'DGG',  'ABT','BGG','YBL'],
                    Slot: ['BNG', 'CQG', 'SWF', 'PGS',   'BSG','CQG','IMONET','IMOPT','SPG','SWF','TG','DBF','MGP'],
                    P2P: ['JBP', 'KYS', 'YBP'],
                    InstantGames: ['SPR'],
                    // IPES  LBK  SBT  SGW TFG OWS YBS
                }
                const needPiwik = ['DGG'];
                if (['web_view', 'lobby'].includes(item.launchMode) // follow M2
                    && Object.values(LobbyVSvendor).flat().includes(item.cgmsVendorCode)) {
                    let gameFolder = '';
                    Object.keys(LobbyVSvendor).map(k=>{
                        if (!gameFolder && LobbyVSvendor[k].includes(item.cgmsVendorCode)) {
                            gameFolder = k;
                        }
                    });

                    if (needPiwik.includes(item.cgmsVendorCode)) { Pushgtagdata('Home', 'Click Feature Banner', 'Home_C_FeatureBanner', 'Home_C_FeatureBanner_ActivityName', data.title); }

                    if (gameFolder) {
                    Router.push(
                        "/cn/Games/" + (gameFolder + '/') + "lobby/?name=" + item.cgmsVendorCode + "&type=&id=0"
                    );
                    }

                    return;
                }
                /*
                if(item.cgmsVendorCode === 'SPR'){
                    if (!isLogin) {
                        global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                        return;
                    }
                    this.QuickStartGame.OpenSPR();
					return;
				}

                if (
                    item.launchMode == "game_id" ||
                    item.launchMode == "web_view"
                ) {
                    if (!isLogin) {
                        global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                        return;
                    }
                    this.QuickStartGame.openGame(GameData);
                    return;
                }

                if (item.launchMode == "lobby") {
                    Router.push("/cn/Games/" + item.cgmsVendorCode);
                    return;
                }
                */
                break;
            case 33:
                PopUpLiveChat();
                break;
            case 202211:
                Router.push(
                    `/sbtwo/sports-im/detail/?sid=1&eid=${
                        item.matchInfo.event_id
                    }&lid=${30455}&OE=false`
                );
                break;

            default:
                break;
        }
    }

    render() {
        const { WalletCloseBanner } = this.state;
        const { item, type, height, width, imgType, bannerFlag, bannerList } =
            this.props;
        return (
            <React.Fragment>
                {!WalletCloseBanner && (
                    <div
                        className="BannerBox"
                        style={{ padding: !type || type == "home" ? "0" : "" }}
                    >
                        <React.Fragment>
                            {type == "home" ? (
                                <Image
                                    src={item.cmsImageUrl}
                                    width={width}
                                    height={height}
                                    style={{
                                        display: "block",
                                    }}
                                    onClick={() => {
                                        this.BannerAction(item);
                                        if (imgType != "CenterBanner") {
                                            Pushgtagdata(
                                                `Banner`,
                                                "Click",
                                                `${item.action.ID}_${item.title}_Home`
                                            );
                                        } else {
                                            Pushgtagdata(
                                                `Banner`,
                                                "Click",
                                                `${item.action.actionName}_Feature_Home`
                                            );
                                        }
                                    }}
                                    alt={item ? item.title : ""}
                                    priority
                                />
                            ) : (
                                <React.Fragment>
                                    <Image
                                        src={item ? item.cmsImageUrl : ""}
                                        priority
                                        width={width ? width : 360}
                                        height={height ? height : 70}
                                        onClick={() => {
                                            this.BannerAction(item);
                                            // Pushgtagdata(
                                            //     `Banner`,
                                            //     "Click",
                                            //     `${item.action.actionName}_${item.category}Page`
                                            // );
                                        }}
                                        alt={item ? item.title : ""}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </div>
                )}
                <LaunchGame
                    QuickStartGame={true}
                    OnRef={(QuickStartGame) =>
                        (this.QuickStartGame = QuickStartGame)
                    }
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        gameInfo: state.game,
    };
}

const mapDispatchToProps = function(dispatch) {
	return {
        changeTab: (i) => {
            dispatch(changeTabAction(i));
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
